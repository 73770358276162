@keyframes up_down_arrow {
  0%   { transform: translateY(0);}
  50%  { transform: translateY(-15px);}
}

footer{
  background-color: @primary;
  color: @white;
  >a.pagetop{
    display: none;
  }
  .promotion{
    margin-bottom: 0;
    padding: 1em;
  }
  .copyright{
    margin-bottom: 0.5em;
  }
}

.go-to-top{
  display: block;
  position: absolute;
  right: 0px;
  bottom: 20px;
  font-size: 1.2rem;
  color: @white;
  text-decoration: none;
  img{
    display: block;
  }
  &:hover{
    color: @white !important;
    text-decoration: none !important;
    img{
      animation: up_down_arrow 1s infinite ease-in-out;
    }
  }
  &__wrap{
    width: 100%;
    position: fixed;
    position: sticky;
    bottom: 0px;
    z-index: 10;
    .transition(all @transition-fast ease-in-out);
    &.hidden{
      .translateY(500px);
      opacity: 0;
    }
    @media @xsmall{
      display: none;
    }
  }
}

.p-contfooter{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: @primary;
  color: @white;
  text-align: center;
  &__head{
    padding: 45px 15px;
  }
  &__logo{
    display: block;
    .hoverOpacity(0.7);
    margin-bottom: 2.2rem;
    .svgicon{
      width: 237px;
      fill: @white;
    }
  }
  &__companyName{
    color: inherit;
    font-size: 18px;
    margin-bottom: 0.3em;
    @media @xsmall{
      font-size: 15px;
    }
  }
  &__addr{
    font-style: normal;
    font-size: 13px;
  }
  &__foot{
    width: 100%;
    border-top: 1px solid #999;
  }
  &__container{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #999;
    @media @xsmall{
      flex-direction: column;
    }
  }
  &__tel-area{
    flex-grow: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 700px;
    // min-height: 180px;
    padding: 35px 15px;
    color: @white;
    @media @xsmall {
      flex-wrap: unset;
      height: auto;
    }
    p{
      color: inherit;
      margin: 0;
      text-align: left;
      font-size: 1.4rem;
      letter-spacing: .09em;
      line-height: 1.8;
    }
    .label{
      padding: 0.3em 0.5em;
      border: 1px solid #999;
      margin-right: 0.8em;
      font-size: 1.2rem;
    }
  }
  &__tel-row{
    display: flex;
    p{
      @media @small-up {
        margin-top: 20px;
        border-right: 1px solid #999;
        padding-right: 20px;
        margin-right: 30px;
      }
      @media @xsmall {
        margin-top: 35px;
        text-align: center;
      }
      &:last-child{
        border-right-width: 0;
        margin-right: 0;
      }
    }
    @media @xsmall {
      flex-direction: column;
    }
  }
  &__tel,
  &__fax{
    display: block;
    margin-bottom: 0.3em;
    font-size: 3rem;
    font-family: 'Roboto', sans-serif;
    letter-spacing: .09em;
    line-height: 1;
    a&, a&:hover, a&:focus, &:visited{
      color: inherit;
    }
    .svgicon{
      fill: @white;
      margin-right: 0.5em;
    }
    @media @xsmall{
      // height: 100px;
    }
  }
  &__tel{
    @media @small-up{
      pointer-events: none;
    }
  }
  &__nav{
    display: flex;
    align-items: stretch;
    color: inherit;
    @media @small-up{
      width: ~"calc(290px + (50vw - 495px) )";
      flex-shrink: 0;
    }
    @media @xsmall{
      border-top: 1px solid @white;
    }
    >a{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding: 15px;
      width: 100%;
      color: inherit;
      font-size: 15px;
      border-left: 1px solid @white;
      .transition(all @transition-fast ease-in-out);
      @media @small-up{
        &:last-child{
          border-right: 1px solid @white;
        }
      }
      @media @xsmall{
        font-size: 13px;
        padding: 20px 10px;
        &, &:visited{
          color: inherit;
        }
        &:first-child{
          border-left-width: 0;
        }
      }
      &:hover, &:focus{
        text-decoration: none;
        color: @body-color;
        background-color: @light;
      }
      &[target="_blank"]{
        position: relative;
        &:after{
          position: absolute;
          bottom: 8px;
          right: 8px;
          font-family: 'fontAwesome';
          line-height: 1;
          content: '\f24d';
          font-size: 14px;
          @media @xsmall{
            font-size: 10px;
          }
        }
      }
      &.is-contact{
        background-color: #BD1C21;
        border-width: 0;
        font-size: 2.4rem;
        line-height: 2;
        letter-spacing: 0.27em;
        text-indent: 0.27em;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        .fa{
          line-height: 1.8;
        }
        &:hover, &:focus{
          &:not(.button){
            background: @white;
            color: #BD1C21;
          }
        }
      }
    }
  }
}

.p-map{
  height: 400px;
  width: 100%;
  @media @xsmall{
    height: 250px;
  }
}

.p-footer{
  padding: 50px 15px;
  &__social{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    letter-spacing: .07em;
    font-size: 1.6rem;
    font-weight: 600;
    >span{
      margin-right: 1em;
      color: #C8C8C9;
      line-height: 2;
      @media @xsmall{
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
    .btn{
      color: @white;
      margin: 0 .5em;
      padding: 0.1em 0.7em;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 300;
      .fa{ margin-right: .5em;}
      &:hover,
      &:focus{
        text-decoration: none;
      }
    }
    .is-fb{
      background-color: @sns-facebook-bg;
      &:hover{ background-color: darken(@sns-facebook-bg,10%);}
    }
    .is-tw{
      background-color: @sns-twitter-bg;
      &:hover{ background-color: darken(@sns-twitter-bg,10%);}
    }
  }
  &__nav{
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
    a{
      display: inline-block;
      margin: 0 1em;
      color: inherit;
      font-size: 13px;
      &:hover, &:focus{
        text-decoration: none;
        color: shade( @white, 20%);
      }
    }
  }
}