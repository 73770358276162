/*
 * Button
 */


// Button Grey
@button-grey-color: @white;
@button-grey-background: @grey;
@button-grey-border: none;
@button-grey-hover-color: @button-grey-color;
@button-grey-hover-background: darken(@button-grey-background, 10%);
@button-grey-hover-border: none;

// Button Border white
@button-border-white-color: @white;
@button-border-white-background: transparent;
@button-border-white-border: 1px solid @button-border-white-color;
@button-border-white-hover-color: @primary;
@button-border-white-hover-background: @white;
@button-border-white-hover-border: @button-border-white-border;



// Button Border white
a.button-border-white,
.button-border-white {
  .button-theme(
    @button-border-white-color,
    @button-border-white-background,
    @button-border-white-border,
    @button-border-white-hover-color,
    @button-border-white-hover-background,
    @button-border-white-hover-border
  );
}

// Button grey
.button-grey {
  .button-theme(
    @button-grey-color,
    @button-grey-background,
    @button-grey-border,
    @button-grey-hover-color,
    @button-grey-hover-background,
    @button-grey-hover-border
  );
}

.button-primary,
a.button-primary {
  .button-theme(
    @white, // color
    @primary, // background
    none, // border
    @white, // hover-color
    @secondary, // hover-background
    none // hover-border
  );
}

.button-recruit,
a.button-recruit,
.button-tertiary,
a.button-tertiary {
  .button-theme(
    @white, // color
    @tertiary, // background
    1px solid @tertiary, // border
    @tertiary, // hover-color
    transparent, // hover-background
    1px solid @tertiary // hover-border
  );
}
.button-border-primary,
a.button-border-primary {
  .button-theme(
    @primary, // color
    transparent, // background
    1px solid @primary, // border
    @white, // hover-color
    @primary, // hover-background
    1px solid @primary // hover-border
  );
}

.c-btn-icon-right{
  @arrow: escape('<svg height="5.84" viewBox="0 0 18.9 5.84" width="18.9" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.34h17.68l-5.09-4.98" fill="none" stroke="@{body-color}" stroke-miterlimit="10"/></svg>');
  @arrow-on: escape('<svg height="5.84" viewBox="0 0 18.9 5.84" width="18.9" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.34h17.68l-5.09-4.98" fill="none" stroke="@{white}" stroke-miterlimit="10"/></svg>');
  &:after{
    display: inline-block;
    margin-left: .5em;
    content: '';
    .size(1.4em,0.5em);
    background: center / cover no-repeat;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
  }
  &:hover, &:focus{
    &:after{
      background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-on}');
    }
  }
}

.btn-dark-icon,
a.btn-dark-icon{
  .button-inverse;
  &:after{
    display: inline-block;
    content: '\f0a9';
    margin-left: 0.5em;
    margin-right: -0.2em;
    font-family: 'fontAwesome';
  }
  &.button-small{
    padding: 0.5em 1em;
    &:after{
      vertical-align: middle;
      font-size: 1.4em;
      line-height: 0.6;
    }
  }
}