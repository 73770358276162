//button font family
.hybsSearch button[type="submit"].button:before{
  font-family: 'hybstemplate';
}

@media @xsmall{
  *{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  html{
    font-size: 10px;
  }
  body{
    font-size: 1.2rem;
  }
  .ui-overlay-a,
  .ui-page-theme-a,
  .ui-page-theme-a .ui-panel-wrapper{
    text-shadow: none;
  }
  .ui-page-theme-a a,
  html .ui-bar-a a,
  html .ui-body-a a,
  html body .ui-group-theme-a a{
    font-weight: 400;
  }
  h1,h2,h3,h4,h5,h6{
    font-weight: 400;
    small{
      font-size: 0.6em;
      color: #808080;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }
  // div.entry p{
  //   margin: 2rem 0;
  // }
  // div.entry dl{
  //   margin: 2rem 0;
  // }
  .ui-content{
    padding-left: 10px;
    padding-right: 10px;
    .ui-listview{
      margin-left: 0;
      margin-right: 0;
      margin-top: -16px;
    }
  }
  .homeContents{
    h3{
      margin-left: 0px;
      margin-right: 0px;
      &.ui-bar{
        margin-left: -10px;
        margin-right: -10px;
      }
    }
  }
  .element_detail{
    font-size: 1.4rem;
  }
  .listContents{
    margin-bottom: 30px;
  }
  #entry_contents{
    margin-bottom: 30px;
  }
  #sitetitle a{
    text-decoration: none;
  }
}
.svgicon{
  display: inline-block;
  vertical-align: middle; 
  width: 30px;
  height: 30px;
}

body,
p,
ul li,
ol li,
dl dd,
dl dt,
table tbody tr th,
table tbody tr td{
  color: @body-color;
  font-variant-ligatures: no-common-ligatures;
}
p{
  letter-spacing: .04em;
  // text-align: justify;
  // &[align="center"]{
  //   text-align: center;
  // }
  html[lang="en"] &{
    word-break: break-all;
  }
}
a{
  // &,
  // &:visited:not(.button){
  //   color: @text-p-color;
  //   .entry &{
  //     text-decoration: underline;
  //   }
  // }
  // &:hover{
  //   text-decoration: none;
  //   color: @text-p-color;
  //   .entry &{
  //     text-decoration: none;
  //   }
  // }
  // &:not(.button):hover{
  //   text-decoration: underline;
  //   color: lighten(@text-p-color,10%);
  //   .entry &{
  //     text-decoration: none;
  //   }
  // }
}

//フォームボタン
.entry_formbanner .element_blog_banner a {
    text-decoration: none;
    color: #fff;
}
a.wrapLink,
a.wrapLink:hover,
a.wrapLink:active{
  text-decoration: none;
}

// フォームプロセスのカレント
.element_mailform .contents>.formflow li.current{
  background-color: @grey;
}
// 送信ボタン
.element_mailform>.contents>form input.submit{
  background-color: @grey;
}

// ウインドウ100%用クラス
#container.elastic{
  width: 100%;
}

// フリーエリア バナー
.element_freearea{
  .banner{
    .hoverOpacity(.7);
    margin-bottom: 1em;
    display: inline-block;
    &:last-child{
      margin-bottom: 0;
    }
    >img{
      max-width: 100%;
      height: auto;
    }
  }
}


// SP：コンテンツトップだったら一覧へのボタンを表示させない
@media @xsmall {
  .is--article-0{
    #footer .pageBottomLink .listpageLinkA{
      display: none;
    }
  }
}

// SP：コンテンツタイトル
@media @xsmall {
  .moduletitle{
    text-align: center;
    background: @primary;
    font-family: 'Roboto', sans-serif;
    color: @white;
  }
}

// SP:フォームボタン
@media @xsmall{
  .formListBtnBlock,div.button{
    border: none;
    display: block;
    height: auto;
    background: none;
    padding: 0;
    &:hover:not(:disabled),
    &:focus:not(:disabled){
      background: none;
      border: none;
    }
  }
}

// SP：カラムレイアウトの設定
@media @xsmall {
  // [class*="column-"]{
  //   margin-bottom: 15px;
  // }
}

// スマホ用フッターナビ
#footer .spFooterNav{
  padding: 10px;
  >p{
    text-align: center;
    text-shadow: none;
    font-weight: normal;
  }
  a{
    display: block;
    margin: 0 auto 30px;
    font-size: 1.5rem;
    &.tel{
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      font-size: 3rem;
      line-height: 1;
      color: @primary;
    }
    &.contact{
      text-align: center;
      background: @primary;
      color: #fff;

    }
  }
}


//ソーシャルボタンレイアウト
#side{
  .socialButton li{
    // display: inline-block;
    // margin-right: 10px;
  }
}

// ポップアップスタイル
button.mfp-close,
button.mfp-arrow{
  &:hover,
  &:focus{
    border: none;
    background-color: transparent;
    color: #FFF;
  }
}



// お問い合わせコンテンツ
.is--inquiry{
  #main{
    width: 100%;
    float: none;
    >.element_mailform{
      margin: 0 auto 3rem;
      max-width: 800px;
    }
  }
}
// メールフォームボタン
.entry_formbanner .element_blog_banner{
  text-align: center;
  a{
    display: inline-block;
    width: 100%;
    max-width: 480px;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    font-size: 1.6rem;
  }
}
// サイトマップ、プライバシー
.is--sitemap,
.is--privacy{
  .breadcrumbs{
    // margin-top: 3rem;
  }
  #main{
    width: 100%;
    float: none;
    >.element{
      max-width: 800px;
      margin: 0 auto 3rem;
    }
  }
  footer:after{
    display: none;
  }
}

// カテゴリタグリスト
.layout_b_pattern6{
  .list_cat{
    text-align: left;
  }
  #main>.block & .list_cat>.title a{
    .button-inverse;
    // &,&:visited{
    //   color: @body-color;
    //   background-color: tint(@tertiary,70%);
    // }
    padding: .2em .5em;
    min-width: 5em;
    height: auto;
    .transition(all @transition-fast ease-in-out);
    &:after{
      display: none;
    }
    &:hover{
      opacity: 1;
    }
    &.current{
      .button-quaternary;
      // &,&:visited{
      //   padding-top: .38rem;
      //   padding-bottom: .38rem;
      //   color: @body-color;
      //   background-color: tint(@tertiary,70%);
      // }
      // border: 1px solid ;
      // &:not(:disabled):hover{
      //   border: 1px solid darken(@primary,10%);
      // }
    }
  }
}

@media @small-up {
  #allbox {
    overflow: visible;
  }
}

// スマホテンプレート　一覧の文字サイズ極小問題
@media @xsmall {
  #hybsMob div.listContents .ui-listview p.discriptionTxt strong{
    font-size: 1.2rem;
    font-weight: 400;
  }
  .ui-listview>li p{
    font-size: 1.2rem;
  }
}