/*
 * Nav
 */
.c-cont-nav{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media @xsmall {
    /* autoprefixer grid: autoplace */
    display: grid;
    grid-template-columns: repeat(2,50%);
    grid-template-rows: repeat(10,auto);
  }
  
  &__item{
    display: block;
    background-color: @secondary;
    text-align: center;
    font-size: 1.4rem;
    letter-spacing: 0.2em;
    padding: 14px 10px;
    border: 0px solid @white;
    border-right-width: 1px;
    &:last-child{ border-right-width: 0;}
    @media @xsmall {
      padding: 12px 6px;
      letter-spacing: 0.1em;
      font-size: 1.2rem;
      &:nth-child(even){ border-right-width: 0;}
      border-top-width: 1px;
      &:nth-child(1),
      &:nth-child(2){
        border-top-width: 0;
      }
    }
    &,a&,a&:visited{
      color: @white;
    }
    .transition(all @transition-fast ease-in-out);
    @media @small-up {
      min-width: 240px;
    }
    a&:hover, a&:focus, a&.current{
      text-decoration: none;
      color: @white;
      background-color: @primary;
      @media @xsmall {
        background-color: tint(@primary, 10%);
      }
    }
    &.span-2{
      @media @xsmall {
        border-right-width: 0;
        grid-column: ~"1 / -1";
      }
    }
    &.span-2 + &{
      @media @xsmall {
        border-right-width: 1px;
        border-top-width: 1px;
      }
    }
  }
}