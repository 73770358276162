/*
 * Title
 */

 .c-title-block{
  text-align: center;
  font-size: 30px;
  margin-bottom: 1em;
  line-height: 1.65;
  @media @xsmall{
    font-size: 22px;
  }
  span{
    display: block;
    font-family: 'Roboto';
    font-weight: 400;
    letter-spacing: 0.4em;
    text-indent: 0.4em;
    @media @xsmall{
      letter-spacing: .2em;
      text-indent: .2em;
    }
  }
  small{
    display: block;
    font-size: .433334em;
    letter-spacing: .05em;
    text-indent: .05em;
    color: inherit;
  }
 }

 .c-title-page{
   text-align: center;
   font-size: 27px; 
   letter-spacing: 0.3em;
   text-indent: 0.3em;
   line-height: 1.65;
   margin-bottom: 1.65em;
   @media @xsmall {
     margin-top: 30px;
   }
   span,small{
     display: block;
   }
   small{
     font-size: 0.63em;
     font-family: 'Roboto';
     font-weight: 100;
     letter-spacing: 0;
   }
 }


.c-title-bottom-border{
  font-size: 2rem;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  color: @secondary;
  line-height: 2;
  text-align: center;
  font-weight: 400;
  position: relative;
  @media @xsmall{
    text-indent: .1em;
    letter-spacing: .1em;
    font-size: 1.8rem;
    padding-bottom: 0;
    border-bottom: none;
  }
  &,div.entry &{
    margin-bottom: 50px;
  }
  &:after{
    content: '';
    display: block;
    margin: 0 auto;
    border-bottom: 3px solid @secondary;
    width: 70px;
    position: absolute;
    left: 0;right: 0;
    bottom: -10px;
  }
}

.c-title-bottom-border-large{
  font-size: 2.4rem;
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  color: @body-color;
  line-height: 2;
  text-align: center;
  font-weight: 400;
  position: relative;
  @media @xsmall{
    text-indent: .1em;
    letter-spacing: .1em;
    font-size: 1.8rem;
    padding-bottom: 0;
    border-bottom: none;
  }
  &,div.entry &{
    margin-bottom: 50px;
  }
  &:after{
    content: '';
    display: block;
    margin: 0 auto;
    border-bottom: 3px solid @secondary;
    width: 70px;
    position: absolute;
    left: 0;right: 0;
    bottom: -10px;
  }
}