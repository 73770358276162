nav#gmenu{ display: none}

#sitetitle h1{
  font-size: 1rem;
}
.p-header{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 0 125px;
  margin-bottom: 0px;
  background-color: @white;
  z-index: 1;
  @media (max-width:1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  &.is-child-open{
    margin-bottom: 53px;
  }
  &__logo{
    .hoverOpacity;
    margin-right: 60px;
    padding: 23px 0;
    h1{ margin: 0; font-size: 1rem;}
    img{
      display: block;
      height: 37.27px; // IE用
    }
  }
}
.p-gnav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 790px;
  color: @body-color;
  &__item{
    position: relative;
    color: inherit;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.04em;
    padding: 1rem;
    &:not(.button):hover,&:not(.button):focus{
      text-decoration: none;
      color: @body-color;
    }
    &:after{
      position: absolute;
      bottom: 5px; left: 50%;
      content: '';
      .size(0,2px);
      background-color: #020080;
      opacity: 0;
      .translateX(-50%);
      .transition(all @transition-fast ease-in-out);
    }
    &:hover,&:focus,&.current{
      &:after{
        width: 80%;
        opacity: 1;
      }
    }
  }
  .button{
    font-weight: 600;
    &:last-child{
      margin-left: 1em;
    }
  }
  .button-border-white{
    border-width: 2px;
    min-width: 215px;
    border-radius: 100px;
    @media all and (-ms-high-contrast:none){
      *::-ms-backdrop, &:before{
        content: '';
        font-size: 1.3em;
      }
    }
  }
}

.mainImg{
  margin-bottom: 10px;
  & &Inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    background: center / cover no-repeat;
    background-color: @body-color;
    .is--service &{ background-image: url('/asset/img/service/jigyoCimg.jpg'); height: 300px;}
    .is--company &{ background-image: url('/asset/img/company/kaishaCimg.jpg'); height: 300px;}
    .is--recruit &{ background-image: url('/asset/img/recruit/recruitCimg.jpg'); height: 300px;}
    .is--works &{ background-image: url('/asset/img/works/worksCimg.jpg'); height: 300px;}
    >h2{
      margin: 0;
      font-size: 2.4rem;
      font-weight: 600;
      letter-spacing: 0.5em;
      text-indent: 0.5em;
      color: @white;
      font-weight: 400;
      line-height: 1.75;
      small{
        display: block;
        font-size: 0.42em;
        color: #29ADBA;
        letter-spacing: 0.15em;
        font-family: 'Montserrat', sans-serif;
      }
      strong{
        display: block;
        font-size: 0.69em;
        font-weight: 400;
      }
    }
  }
  .p-service-nav,
  .p-company-nav,
  .p-recruit-nav{
    position: absolute;
    bottom: 0; left: 0;
    width: 100%;
    &__item{
      background-color: fade(#575858, 80%);
      border-color: @white;
      &,a&,a&:visited{
        color: @white;
      }
      &:hover, &:focus, &.current{
        color: @body-color;
        background-color: @white;
      }
    }
  }
}